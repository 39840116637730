export const Environment = {
		  production: true,
		  appVersion: require('../../package.json').version + '-prod',
		  analysisAPIRoot: '//elb.revenup.cloud',
		  iEAPIRoot: '//elb.revenup.cloud',
		  OIAPIRoot: '//elb.revenup.cloud',
		  dataCollectionAPIRoot: 'elb.revenup.cloud:442/api/dc-v1',
		  revOperationAPIRoot: 'elb.revenup.cloud/api/revops',
		  utmAPIRootWithoutHyphen: 'elb.revenup.cloud:444/api/utm-v1/',
		  utmAPIRoot: 'elb.revenup.cloud:444/api/utm-v1',
		  taskMiningAPIRoot: '-be1.opxce.com/api/tmv1',
		  IIAPIRoot: '//elb.revenup.cloud',
		  revOpsRoot: '//elb.revenup.cloud/api/revops/',
		  revenupRoot: '//elb.revenup.cloud:446/api/revenup/',
		  genAI: '//elb.revenup.cloud:446/api/revenup/intel/'
		};